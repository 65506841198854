<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row ref="1">

            <v-col>
                <!--<main-img></main-img>-->
                <div class="" style="width: 100%;">
                    <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                일번지소개
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                일번지소개
                            </h1>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="12" md="12" class="">
                            <h1 class="about-sub-title">인사말</h1>
                        </v-col>
                        <v-col cols="12" md="9"  v-resize="onResize">
                            <template v-if="isData">
                                <div class="ql-editor">
                                    <span v-html="text"></span>
                                </div>
                            </template>
                            <template v-else>
                                <h1>추억이 물들어 가는 대둔산 운주 계곡일번지!</h1>
                                <h2>여러분을 환영합니다.</h2>
                                <br>
                                <h2>도시의 화려함에서 벗어나 자연의 고마움과 신비로움을 느낄수있는 곳!</h2>
                                <h2>대둔산과 천둥산이 만들어 내는 1급수가 흐르는 중부권 최고의 천연계곡 입니다.</h2>
                                <br>
                                <h2>별들이 춤추는 밤하늘과 계곡의 합창이 어우러지는 계곡1번지!</h2>
                                <h2>계곡의 중심 1번지라는 자부심이 넘처흐르는 펜션 입니다.</h2>
                                <br>
                                <h2>여러분을 맑고 깨끗한 공기를 마시며</h2>
                                <h2>아룸다운 사람들과 추억을 이야기 하는 동안</h2>
                                <h2>여러분의 마음에 아름다운 추억이 물들어 갈 것입니다.</h2>
                                <br>
                                <h1>계곡의 자부심! 대둔산 운주 계곡일번지!</h1>
                                <h2>여러분을 초대합니다.</h2>

                            </template>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">둘러보기</h1>
                        </v-col>

                        <v-col>
                            <v-row class="my-10">
                                <!--<v-col cols="12" class="pa-0 hidden-sm-and-down">
                                    <v-col cols="12">
                                        <v-carousel
                                                :continuous="true"
                                                cycle
                                                hide-delimiter-background
                                                :height="$vuetify.breakpoint.xs ? 300 : 600"
                                                style="border-radius: 15px !important;"
                                        >
                                            <v-carousel-item
                                                    v-for="(roomImg, i) in imgList"
                                                    :key="i"
                                                    :src="roomImg.roomImgRoute"
                                                    inset
                                            >
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" class="pa-0 hidden-md-and-up">
                                    <div>
                                        <swiper class="swiper"
                                                :options="swiperOption"
                                                ref="roomSwiper1"
                                        >
                                            <template v-for="(room, i) in imgList">
                                                <swiper-slide>
                                                    <img class="main-slide-img-4" :src="room.roomImgRoute"
                                                         @click="goToRoomInfo(room)"/>
                                                </swiper-slide>
                                            </template>
                                        </swiper>
                                    </div>
                                </v-col>-->
                                <v-col cols="12" class="pa-0">
                                    <div data-aos="fade-up" data-aos-duration="700" data-aos-offset="300" data-aos-delay="150">
                                        <swiper class="swiper"
                                                :options="swiperOption2"
                                                ref="roomSwiper"
                                        >
                                            <template v-for="(room, i) in imgList">
                                                <swiper-slide>
                                                    <img class="main-slide-img-2" :src="room.roomImgRoute"
                                                         @click="goToRoomInfo(room)"/>
                                                </swiper-slide>
                                            </template>
                                            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                                            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                                            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                                        </swiper>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row ref="3">
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">배치도 및 오시는 길</h1>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="about-map-img">
                                <img :src="imgInfo_bat.roomImgRoute" />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="about-map-img">
                                <img :src="imgInfo_wtc.roomImgRoute" />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row ref="4" >
            <v-col>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">길 찾기</h1>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-btn
                                    outlined
                                    color="indigo"
                                    @click="getNaverMap"
                                    style="width: 150px;"
                                    class="my-1"
                            >
                                네이버 길찾기
                            </v-btn>
                            <!--<v-btn
                                    outlined
                                    color="indigo"
                                    @click="getRoadMap"
                                    style="width: 150px;"
                                    class="my-1 ml-3"
                            >
                                오시는길 약도
                            </v-btn>-->
                        </v-col>
                        <v-col cols="12" md="9">
                            <div id="map" style="width:100%;height:480px;"></div>
                        </v-col>
                        <v-col cols="12" md="3"></v-col>
                        <v-col cols="12" md="9" class="my-5">
                            <div class="text--primary">
                                <span class="material-icons">location_on</span>
                                <span class="about-map-text">{{this.$store.state.admin.homeInfo.saupAddr}}</span>
                            </div>
                            <div class="text--primary">
                                <span class="material-icons">call</span>
                                <span class="about-map-text">010-3955-0677</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'
    import MainImg from '../../components/MainImg'
    export default {
        name: 'AboutMain',
        components: {
            Swiper,
            SwiperSlide,
            MainImg
        },
        data: () => ({
            /*swiperOptionTop: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                loopAdditionalSlides:3,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },*/
            swiperOption: {

                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
            swiperOption2: {
                loopAdditionalSlides:3,
                spaceBetween: 10,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },

                }
            },
            imgList: [],
            imgInfo_bat: {},
            imgInfo_wtc: {},
            comnInfo: {},
            text: "",
            isData: false,
            isMob: false, // 화면 모바일인지 확인



        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);

            if (window.innerWidth < 700) {

                this.isMob = true;

            } else {

                this.isMob = false;
            }

            this.getPageInfo();
            this.getImgList();
            this.getImgList1();
        },
        mounted() {
            this.$nextTick(() => {

                this.$nextTick(() => {
                    var mapOptions = {
                        center: new naver.maps.LatLng(36.01830091015144, 127.35030939019462),
                        zoom: 11
                    };
                    /*36.01836533947182, 127.34992248215671*/
                    var map = new naver.maps.Map('map', mapOptions);

                    var marker = new naver.maps.Marker({
                        position: new naver.maps.LatLng(36.01830091015144, 127.35030939019462),
                        map: map
                    });


                })
            })
        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            },
            roomSwiper1() {
                return this.$refs.roomSwiper1;
            }

        },
        methods: {

            onResize() {

                let isMob = false;

                if (window.innerWidth < 700) {

                    isMob = true;

                } else {

                    isMob = false;

                }

                if(this.isMob !== isMob){

                    this.isMob = isMob;

                    this.getPageInfo();
                }
            },

            getPageInfo(){

                let formdata = {comnGubun: 'textGreet',};

                if (this.isMob){

                    formdata['comnOrder'] = '1';

                }else {
                    formdata['comnOrder'] = '0';
                }


                return this.$store.dispatch("admin/getComn",formdata)
                    .then((resp) => {
                        if (resp.message === undefined) {
                            //alert("잘못된 요청입니다.");
                            //window.history.back();
                            return;
                        }

                        this.isData = true;
                        this.comnInfo = resp.message;

                        this.text = this.comnInfo.comnCont;
                        //this.quill.root.innerHTML = this.comnInfo.comnCont;

                        this.$nextTick(() => {
                            let pageId = this.$getParameterByName('pageId');

                            if (pageId.length > 0) {
                                //this.hash("/place/main", pageId);
                                setTimeout(() => {
                                    this.scrollMeTo(pageId);
                                }, 300);
                            }
                        })

                    })
                    .catch((err) => {
                    })

            },
            getNaverMap() {
                var ret = window.open('https://map.naver.com/v5/directions/-/14176572.474197228,4303140.0601490475,%EA%B3%84%EA%B3%A1%EC%9D%BC%EB%B2%88%EC%A7%80,1229054824,PLACE_POI/-/transit?c=14176572.4741978,4303140.0601491,18,0,0,0,dh', '_blank');

            },
            getRoadMap(){
                let options = 'top=10, left=10, width=600, height=800, status=no, menubar=no, toolbar=no, resizable=no';
                let route = '/popup/place/roadMap';
                let popNm = "오시는길";
                window.open(route, popNm, options);
            },
            scrollMeTo(refName) {
                var element = this.$refs[refName];
                var top = element.offsetTop;
                //window.scrollTo(0, top, {behavior: 'smooth'});
                window.scrollTo({left:0, top:top, behavior: 'smooth'});
            },
            // 둘러보기 이미지
            getImgList() {
                return this.$store.dispatch("admin/getRoomImg", {roomId: 'ABO'})
                    .then((resp) => {
                        setTimeout(() => {

                            this.imgList = resp.message;
                            this.$nextTick(function () {
                                this.roomSwiper.$swiper.slideTo(5, 300);
                                this.roomSwiper1.$swiper.slideTo(5, 300);
                            })
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

            // 배치도 이미지
            getImgList1(){
                return this.$store.dispatch("admin/getRoomImg", {roomId: 'BAT'})
                    .then((resp) => {
                        setTimeout(() => {

                            this.imgInfo_bat = resp.message[0];
                            this.$nextTick(function () {
                                return this.$store.dispatch("admin/getRoomImg", {roomId: 'WTC'})
                                    .then((resp) => {
                                        setTimeout(() => {

                                            this.imgInfo_wtc = resp.message[0];

                                        }, 300)
                                    })
                                    .catch((err) => {
                                    })
                            })
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
        }

    }
</script>

<style lang="scss" scoped>
    .thumb-example {
        /*height: 400px;*/
        /*background-color: black;*/
    }

    .swiper {
        .swiper-slide {
            background-size: cover;
            background-position: center;

            &.slide-1 {
                background-image: url('../../assets/slider/mimg01.jpg')
            }

            &.slide-2 {
                background-image: url('../../assets/slider/mimg02.jpg')
            }

            &.slide-3 {
                background-image: url('../../assets/slider/mimg03.jpg')
            }

            &.slide-4 {
                background-image: url('../../assets/slider/mimg04.jpg')
            }

            &.slide-5 {
                background-image: url('../../assets/slider/mimg05.jpg')
            }
        }

        &.gallery-top {
            /*height: 100%;*/
            height: 400px;
            width: 100%;
            float: left;
        }

        &.gallery-thumbs {
            /*height: 20%;*/
            height: 80px;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            float: right;
        }

        &.gallery-thumbs .swiper-slide {
            width: 20%;
            height: 100%;
            opacity: 0.4;
        }

        &.gallery-thumbs .swiper-slide-active {
            opacity: 1;
        }

        &.gallery-depiction {
            height: 320px;
            width: 100%;
            float: right;
        }

        &.gallery-depiction-sm {
            height: 100%;
            width: 100%;
            float: right;
        }

    }
</style>

