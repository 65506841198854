<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row class="" align="center" justify="center" style="margin:0 !important;">
        <div class="" style="width: 100%;">
            <v-row class="main-title-img-2" align="center" justify="center">
                <v-col
                        class="text-center"
                        cols="12"
                >
                    <h1 class="text-h4 font-weight-bold white--text">
                        {{mainImgInfo.imgName}}
                    </h1>
                </v-col>
            </v-row>
        </div>


    </v-row>
</template>

<script>

    export default {
        name: 'MainImg',
        data: () => ({

            pathname: "",
            title: "",
            mainImgInfo: {},
            isText: true,
        }),
        mounted() {

        },
        created() {

            this.pathname = window.location.pathname;
            //this.setTitle();
            this.fetch()

        },
        methods: {
            fetch() {

                let formdata = {
                    imgGubun: 'CA',
                    imgType: this.pathname,

                };

                return this.$store.dispatch("imgInfo/getImgInfo", formdata)
                    .then((resp) => {

                        if (resp.message === undefined) {

                        } else {
                            this.mainImgInfo = resp.message;
                            this.setImgCss();
                        }

                    })
                    .catch((err) => {
                    })
            },
            setImgCss(){

                let background = "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../slider/slide4.png')";

                $('.main-title-img-4').css('background',background);
                /*background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url('../slider/slide4.png');*/

            },
            setTitle() {

                switch (this.pathname) {
                    case "1": {
                        this.title = "일번지소개";
                        break;
                    }
                    case "/place/main": {
                        this.title = "시 설 안 내";

                        break;
                    }
                    case "3": {
                        this.title = "이 용 안 내";
                        break;
                    }
                    case "4": {
                        this.title = "실시간예약";
                        break;
                    }
                    case "5": {
                        this.title = "예 약 확 인";
                        break;
                    }
                    case "6": {
                        this.title = "커 뮤 니 티";
                        break;
                    }

                }
            },

        },
        computed: {},
    }
</script>

